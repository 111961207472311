// src/App.js
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { AuthProvider } from './auth/useAuth';
import './index.css';
import FooterOperated from "./components/FooterOperated";
import Verifier from "./components/Verifyer";
import ProtectedRoute from "./components/ProtectedRoute";
import { Container } from "react-bootstrap";
import LogoutButton from "./components/LogoutButton";
import Login from "./components/Login";

function App() {
    return (
        <AuthProvider >
            <Router>
                <Routes>
                <Route path="/" element={
                      <>
                      <Login />

                      </>
                      } />
            <Route path="/verifier-scanner" element={
                    <>
                    <Container>
                    <LogoutButton/>
                    <ProtectedRoute path="/" component={Verifier} />
                            </Container>
                            <ProtectedRoute component={FooterOperated} />
                        </>
                    } />
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;
