import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, updateDoc, doc } from 'firebase/firestore';
import { db } from '../auth/firebase';
import { auth } from '../auth/firebase';
import { Container, Row, Col } from 'react-bootstrap';
import HybridLogo from '../logo_Hybrid.png';
import { Scanner } from '@yudiel/react-qr-scanner';

const Verifier = () => {
  const [data, setData] = useState('');
  const [manualInput, setManualInput] = useState('');
  const [firestoreData, setFirestoreData] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState('KEF-bounce-back'); // Default value
  const [currentUser, setCurrentUser] = useState(null); // State for current user

  // Load selectedDatabase from localStorage
  useEffect(() => {
    const storedDatabase = localStorage.getItem('selectedDatabase');
    if (storedDatabase) {
      setSelectedDatabase(storedDatabase);
    }
  }, []);

  // Save selectedDatabase to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('selectedDatabase', selectedDatabase);
  }, [selectedDatabase]);

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user); // Set the current user
    });
    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  // Handle QR Code scanning
  const handleScan = async (result) => {  
    if (result && result.length > 0) {
      const rawValue = result[0].rawValue || '';
      if (rawValue) {
        setData(rawValue);
        setManualInput(rawValue);
        await fetchDataFromFirestore(rawValue); // Fetch data immediately after setting data
      } else {
        alert('No valid text found in the scan result');
      }
    } else {
      alert('No result object found');
    }
  };

  const handleError = (err) => {
    console.error('QR Scan Error:', err);
  };

  const handleManualInputChange = (e) => {
    setManualInput(e.target.value);
  };

  const handleDatabaseChange = (e) => {
    setSelectedDatabase(e.target.value);
  };

  const handleManualSubmit = async () => {
    if (manualInput) {
      setData(manualInput);
      await fetchDataFromFirestore(manualInput); // Fetch data for manual input
    }
  };

  const clearInput = () => {
    setManualInput('');
    setData(''); // Clear the displayed data
    setFirestoreData([]); // Clear the fetched data
  };

  const fetchDataFromFirestore = async (qrCode) => {
    if (!selectedDatabase) {
      alert('Please select a database before scanning or entering a QR code.');
      return; // Exit the function if no database is selected
    }

    const q = query(collection(db, selectedDatabase), where('uuid', '==', qrCode));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    if (data.length === 0) {
      alert('Unique code not found.');
    }

    setFirestoreData(data);
  };

  const handleMarkAsUsed = async (id) => {
    const enteredPasscode = prompt("Enter Passcode:");
    if (enteredPasscode === '1234') {
      if (currentUser && window.confirm('Are you sure you want to mark this item as used?')) {
        try {
          const docRef = doc(db, selectedDatabase, id);
          await updateDoc(docRef, { 
            status: 'used',
            updatedBy: currentUser.displayName || currentUser.email, // Use user's display name or email
            updatedAt: new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh" })
          });
          setFirestoreData(prevData => prevData.map(item => 
            item.id === id ? { ...item, status: 'used', updatedBy: currentUser.displayName || currentUser.email } : item
          ));
        } catch (error) {
          console.error('Error updating document: ', error);
        }
      } else {
        alert('Invalid Passcode');
      }
    }
  };

  return (
    <Container className='mt-4 ContainerWrap'>
      <img src={HybridLogo} alt='Hybrid Logo' className='HybridLogo'/>
      <hr/>
      <Row className='justify-content-center'>
        <h2>Alfa Co. Verifier</h2>
        <p>Please Select:</p>
        <select value={selectedDatabase} onChange={handleDatabaseChange} className="form-control mb-3">
          <option value="KEF-bounce-back">Kids Eat Free</option>
          <option value="PS-bounce-back-hybrid-opening">P. Sultan Opening</option>
          <option value="RF-bounce-back-hybrid-opening">Roshan Front Opening</option>
        </select>
        <div className='scannerWrap mb-4'>
          <Scanner
            scanDelay={3000}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '10px', height: '10px' }}
          />
        </div>
        <div className='d-flex justify-content-center'>
          <Row>
            <Col className='col-8'>
              <div style={{ position: 'relative', width: '100%' }}>
                <input
                  className='form-control w-100'
                  type='text'
                  value={manualInput}
                  onChange={handleManualInputChange}
                  placeholder='Enter QR code manually'
                  style={{ paddingRight: '2.5rem' }}
                />
                {manualInput && (
                  <button
                    onClick={clearInput}
                    style={{
                      position: 'absolute',
                      right: '5px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      background: 'transparent',
                      border: 'none',
                      cursor: 'pointer',
                      fontSize: '1rem',
                      color: '#ccc',
                    }}
                  >
                    &#x2715;
                  </button>
                )}
              </div>
            </Col>
            <Col className='col-3'>
              <button className='btn btn-success justify-content-center' onClick={handleManualSubmit}>Submit</button>
            </Col>
          </Row>
        </div>
        {firestoreData.length > 0 && (
          <div className='border mt-3 status-wrapper'>
            <h3 className='mt-3'>Status for: {data}</h3>
            {firestoreData.map(item => (
              <div className='border rounded p-2 mb-5 status-wrapper-in' key={item.id}>
                <Row>
                  <Col>
                    <p style={{ color: item.status === 'used' ? 'red' : 'green' }}>
                      <strong>Status:</strong> <h4>{item.status}</h4>
                    </p>
                  </Col>
                  <Col>
                    <button
                      className='btn btn-success mt-3'
                      onClick={() => handleMarkAsUsed(item.id)}
                      disabled={item.status === 'used'}
                    >
                      {item.status === 'used' ? 'Already Used' : 'Mark as Used'}
                    </button>
                  </Col>
                </Row>
                <hr/>
                <h3 className='mt-3'><strong>{item.guestName}</strong></h3>
                <div>{item.email}</div>
                <div>{item.mobile}</div>
                <br/>
                <br/>
              </div>
            ))}
          </div>
        )}
      </Row>
    </Container>
  );
};

export default Verifier;