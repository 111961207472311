import React from 'react'

const FooterOperated = () => {
  return (
    <div className='position-fixed bottom-0 footerWrap'>
      <footer className='pb-3 text-center'>Powered by <strong>SUFRA</strong></footer>
    </div>
  )
}

export default FooterOperated;
