import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBg_DPL3BEgmWkWx-M5ito1MMxAF8Ow588",
  authDomain: "alfacodatabasedos.firebaseapp.com",
  projectId: "alfacodatabasedos",
  storageBucket: "alfacodatabasedos.appspot.com",
  messagingSenderId: "510149337226",
  appId: "1:510149337226:web:5b2a03e2f4022530bfcd73",
  measurementId: "G-J8WB55V821"
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = firebase.firestore();

export { auth, db };