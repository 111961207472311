// src/components/LogoutButton.js
import React from 'react';
import { auth } from '../auth/firebase';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/useAuth'; // Import useAuth hook

const LogoutButton = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth(); // Get current user from useAuth hook

    const handleLogout = async () => {
        try {
            await auth.signOut();
            navigate('/'); // Redirect to the login page after logout
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    // Conditionally render the logout button
    return (
        currentUser ? (
            <button className="btn btn-danger position-absolute top-0 end-0 m-3" onClick={handleLogout}>Logout</button>
        ) : null
    );
};

export default LogoutButton;
