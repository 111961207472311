import React, { useState } from 'react';
import { auth } from '../auth/firebase';
import { useNavigate } from 'react-router-dom';
import '../index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        try {
            // Attempt to sign in with the provided email and password
            await auth.signInWithEmailAndPassword(email, password);
            navigate('/verifier-scanner');  // Redirect to a protected route
        } catch (error) {
            setError(error.message); // Set error message if login fails
        }
    };

    return (
        <Container>
            <div className='justify-content-center align-items-center login'>
                <h2 className='text-center mt-5'>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Email:</label>
                        <input
                            className='form-control'
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            autoComplete="email" // Added autocomplete attribute
                        />
                    </div>
                    <div>
                        <label>Password:</label>
                        <div className="input-group">
                            <input
                                className='form-control'
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                autoComplete="current-password" // Added autocomplete attribute
                            />
                            <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? "Hide" : "Show"}
                            </button>
                        </div>
                    </div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <button className='btn btn-success mt-3' type="submit">Login</button>
                </form>
            </div>
        </Container>
    );
};

export default Login;